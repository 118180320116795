import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import Product1 from "../images/image-product-1.jpg";
import Product2 from "../images/image-product-2.jpg";
import Product3 from "../images/image-product-3.jpg";
import Product4 from "../images/image-product-4.jpg";

const MAX_QUANTITY_ALLOWED = 8; // Maximum quantity allowed in cart

type ImagesContext = {
  nextImage: () => void;
  prevImage: () => void;
  getCurrentImageIndex: () => number;
  setCurrentImageIndex: (index: number) => void;
  getImages: () => string[];
  enterFullScreenMode: () => void;
  exitFullScreenMode: () => void;
  isFullScreenMode: boolean;
  quantity: number;
  cartQuantity: number;
  incrementQuantity: () => void;
  decrementQuantity: () => void;
  markAddToCartClicked: () => void;
  showMaxQuantityToast: boolean;
  recordTimeForToast: () => void;
  recordedTimeForToast: number;
  clearCart: () => void;
};

const ImagesContext = createContext({} as ImagesContext);

export function useImages() {
  return useContext(ImagesContext);
}

// Create a provider component
export function ImagesProvider(props: { children: ReactNode }) {
  const { children } = props;
  const images = [Product1, Product2, Product3, Product4];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [quantity, setQuantity] = useState(
    Number(localStorage.getItem("quantity")) || 0
  );
  const [markAddToCartClicked, setMarkAddToCartClicked] = useState(
    quantity > 0
  );
  const [showMaxQuantityToast, setShowMaxQuantityToast] = useState(false);
  const [recordedTimeForToast, setRecordedTimeForToast] = useState(
    new Date().getTime()
  );
  useEffect(() => {
    if (markAddToCartClicked)
      localStorage.setItem("quantity", quantity.toString());
  }, [quantity, markAddToCartClicked]);

  const nextImage = () =>
    setCurrentImageIndex((prev) => (prev + 1) % images.length);

  const prevImage = () =>
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);

  const getCurrentImageIndex = () => currentImageIndex;

  const incrementQuantity = () => {
    console.log("incrementQuantity");
    console.log("quantity", quantity);

    if (quantity >= MAX_QUANTITY_ALLOWED) {
      //   setTimeout(() => {
      setShowMaxQuantityToast(true);
      //   }, 1000);
    } else {
      setQuantity((prev) => prev + 1);
    }
  };

  const decrementQuantity = () => {
    setQuantity((prev) => {
      setShowMaxQuantityToast(false);
      return Math.max(0, prev - 1);
    });
  };
  return (
    <ImagesContext.Provider
      value={{
        nextImage,
        prevImage,
        getCurrentImageIndex,
        setCurrentImageIndex,
        getImages: () => images,
        enterFullScreenMode: () => setFullScreenMode(true),
        exitFullScreenMode: () => setFullScreenMode(false),
        isFullScreenMode: fullScreenMode,
        quantity,
        cartQuantity: markAddToCartClicked ? quantity : 0,
        incrementQuantity,
        decrementQuantity,
        markAddToCartClicked: () => setMarkAddToCartClicked(true),
        showMaxQuantityToast: showMaxQuantityToast,
        recordTimeForToast: () => setRecordedTimeForToast(new Date().getTime()),
        recordedTimeForToast: recordedTimeForToast,
        clearCart: () => {
          setQuantity(0);
          setMarkAddToCartClicked(false);
          localStorage.setItem("quantity", "0");
        },
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
}
