import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import React from "react";
import "./RightSlidingMenu.css";

const RightSlidingMenu = ({ open = false, onClose }) => {
  return (
    <SlidingPane
      hideHeader={true}
      shouldCloseOnEsc={true}
      isOpen={open}
      from="left"
      className="responsive-sliding-pane" // Add a custom class for responsive styles
      onRequestClose={onClose}
      children={<Menu visible={open} onCloseButtonClick={onClose} />}
    ></SlidingPane>
  );
};

function Menu(props: { visible: boolean; onCloseButtonClick: () => void }) {
  const { visible, onCloseButtonClick } = props;
  const navItems = ["Collections", "Men", "Women", "About", "Contact"];

  return (
    <div className="md:hidden">
      <CloseIcon
        closeButtonClick={onCloseButtonClick}
        fontSize={"32px"}
        weight={200}
      />
      <nav className="flex flex-col space-y-4 py-3 px- text-lg">
        {navItems.map((item) => (
          <a
            key={item}
            href="#"
            className="text-gray-600 hover:text-black hover:underline underline-offset-2"
          >
            {item}
          </a>
        ))}
      </nav>
    </div>
  );
}

const CloseIcon = ({ closeButtonClick, fontSize, weight }) => {
  const myStyle = {
    fontSize: `${fontSize}`,
    fontVariationSettings: `'FILL' 0, 'wght' ${weight}, 'GRAD' -25`,
  };

  return (
    <button
      className="mb-6  material-symbols-rounded text-slate-600 hover:text-black hover:font-black"
      style={myStyle}
      onClick={() => closeButtonClick()}
    >
      close
    </button>
  );
};

export default RightSlidingMenu;
