import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useImages } from "./context/ImagesContext";
import {
  ChevronLeft,
  ChevronRight,
  CircleXIcon,
  Clock1Icon,
  XIcon,
} from "lucide-react";

const FullImages = () => {
  const {
    isFullScreenMode,
    exitFullScreenMode,
    getImages,
    getCurrentImageIndex,
    setCurrentImageIndex,
    prevImage,
    nextImage,
  } = useImages();

  const borderShapeRef = useRef<HTMLDivElement>(null);

  const thumbnailsRef = useRef<HTMLImageElement[] | null[]>([]);
  const [imageLoaded, setImageLoaded] = useState(false); // state variable used to control rendering of BorderShape, without this BorderShape appears briefly before thumbnails are loaded
  const handleThumbnailClick = (index: number) => {
    const ref = thumbnailsRef.current[index];

    if (ref && borderShapeRef.current) {
      borderShapeRef.current.style.left = `${ref.offsetLeft}px`;
    }
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      const ref = thumbnailsRef.current[getCurrentImageIndex()];
      if (ref && borderShapeRef.current) {
        borderShapeRef.current.style.left = `${ref.offsetLeft}px`;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getCurrentImageIndex()]);

  useEffect(() => {
    const ref = thumbnailsRef.current[getCurrentImageIndex()];
    if (ref && borderShapeRef.current) {
      borderShapeRef.current.style.left = `${ref.offsetLeft}px`;
    }
  });

  if (!isFullScreenMode) return null;

  return (
    <div
      className=" closeOutsideClick absolute hidden md:flex justify-center bg-black bg-opacity-75 inset-0 z-30 opacity-100 w-screen min-w-[100vw] min-h-[150vh] h-max "
      onClick={(e) => {
        console.log(e.target);
        const target = e.target as HTMLElement;

        if (target.classList.contains("closeOutsideClick"))
          exitFullScreenMode();
      }}
    >
      <div className="relative w-1/2 max-w-[800px] h-1/2 max-h-[800px]  my-[20vh] rounded-lg">
        <div className="relative">
          {imageLoaded && (
            <button
              className="ml-[95%] w-20 duration-300"
              onClick={exitFullScreenMode}
            >
              <XIcon size={32} color="rgb(251 146 60)" strokeWidth={2} />
            </button>
          )}
          <img
            src={getImages()[getCurrentImageIndex()]}
            alt="Sneaker"
            className="w-full h-auto rounded-lg mt-1 cursor-pointer"
            onLoad={() => setImageLoaded(true)}
          />
          {imageLoaded && (
            <button
              onClick={prevImage}
              className="absolute -left-[18px] top-1/2 bg-white rounded-full p-2 hover:text-orange-600"
            >
              <ChevronLeft strokeWidth={1.8} />
            </button>
          )}
          {imageLoaded && (
            <button
              onClick={nextImage}
              className="absolute -right-[18px] top-1/2 bg-white rounded-full p-2 hover:text-orange-600"
            >
              <ChevronRight strokeWidth={1.8} />
            </button>
          )}
        </div>
        <div className="relative justify-center hidden gap-4 mt-4 md:flex lg:gap-8 xl:gap-16 2xl:gap-20">
          <BorderShape isVisible={imageLoaded} ref={borderShapeRef} />
          {getImages().map((img, index) => (
            <img
              ref={(el) => (thumbnailsRef.current[index] = el)}
              key={index}
              src={img}
              alt={`Thumbnail ${index + 1}`}
              className={`w-20 h-20 rounded-lg cursor-pointer ${
                index === getCurrentImageIndex()
                  ? "opacity-50"
                  : "opacity-100 z-10"
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const BorderShape = forwardRef<HTMLDivElement, { isVisible: boolean }>(
  (props, ref) => {
    const { isVisible } = props;

    return (
      <div
        ref={ref}
        className={`absolute inset-0 w-20 h-20 rounded-lg border-2 bg-zinc-50 border-orange-500 ${
          isVisible ? "block" : "hidden"
        }`}
      ></div>
    );
  }
);

export default FullImages;
