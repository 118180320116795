import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  ShoppingCart,
  ChevronLeft,
  ChevronRight,
  Minus,
  Plus,
} from "lucide-react";
import { useImages } from "./context/ImagesContext";

function HeroSection() {
  const {
    nextImage,
    prevImage,
    getCurrentImageIndex,
    setCurrentImageIndex,
    getImages,
    enterFullScreenMode,
    quantity,
    markAddToCartClicked,
    incrementQuantity,
    decrementQuantity,
    recordTimeForToast,
  } = useImages();

  const [imageLoaded, setImageLoaded] = useState(false); // state variable used to control rendering of BorderShape, without this BorderShape appears briefly before thumbnails are loaded
  const borderShapeRef = useRef<HTMLDivElement>(null);

  const thumbnailsRef = useRef<HTMLImageElement[] | null[]>([]);

  const handleThumbnailClick = (index: number) => {
    const ref = thumbnailsRef.current[index];

    if (ref && borderShapeRef.current) {
      borderShapeRef.current.style.left = `${ref.offsetLeft}px`;
    }
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const handleResize = () => {
      const ref = thumbnailsRef.current[getCurrentImageIndex()];
      if (ref && borderShapeRef.current) {
        borderShapeRef.current.style.left = `${ref.offsetLeft}px`;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [getCurrentImageIndex()]);

  return (
    <div className="mx-auto p-0 md:flex md:space-x-8 md:mt-8 max-w-[1000px]">
      {/* Product Images */}
      <div className="relative md:w-1/2">
        <img
          src={getImages()[getCurrentImageIndex()]}
          alt="Sneaker"
          className="w-full h-auto cursor-pointer md:rounded-lg"
          onClick={enterFullScreenMode}
          onLoad={() => setImageLoaded(true)}
        />
        <button
          onClick={prevImage}
          className="absolute p-2 bg-white rounded-full left-4 top-1/2 md:hidden hover:text-orange-600"
        >
          <ChevronLeft strokeWidth={1.8} />
        </button>
        <button
          onClick={nextImage}
          className="absolute p-2 bg-white rounded-full right-4 top-1/2 md:hidden hover:text-orange-600"
        >
          <ChevronRight strokeWidth={1.8} />
        </button>

        <div className="relative justify-between hidden mt-4 md:flex">
          <BorderShape isVisible={imageLoaded} ref={borderShapeRef} />
          {getImages().map((img, index) => (
            <img
              ref={(el) => (thumbnailsRef.current[index] = el)}
              key={index}
              src={img}
              alt={`Thumbnail ${index + 1}`}
              className={`w-20 h-20 rounded-lg cursor-pointer ${
                index === getCurrentImageIndex()
                  ? "opacity-50"
                  : "opacity-100 z-10"
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>

      {/* Product Info */}
      <div className="md:w-1/2 mt-8 md:mt-0 mx-[4vw] md:mx-0">
        <h2 className="mb-4 font-bold text-orange-500 uppercase">
          Sneaker Company
        </h2>
        <h1 className="mb-4 text-3xl font-bold">
          Fall Limited Edition Sneakers
        </h1>
        <p className="mb-4 text-gray-600">
          These low-profile sneakers are your perfect casual wear companion.
          Featuring a durable rubber outer sole, they'll withstand everything
          the weather can offer.
        </p>
        <div className="flex items-center mb-4">
          <span className="mr-4 text-3xl font-bold">$125.00</span>
          <span className="px-2 py-1 font-bold text-orange-500 bg-orange-100 rounded">
            50%
          </span>
        </div>
        <p className="mb-6 text-gray-400 line-through">$250.00</p>
        <div className="flex flex-col mb-20 space-y-4 md:flex-row md:space-y-0 md:space-x-4 md:mb-0">
          <div className="flex items-center justify-between bg-gray-100 rounded-md min-w-32">
            <button
              onClick={decrementQuantity}
              className="p-3 pr-3 hover:text-orange-600"
            >
              <Minus size={20} strokeWidth={3} />
            </button>
            <span className="font-bold">{quantity}</span>
            <button
              onClick={() => {
                incrementQuantity();
                recordTimeForToast();
              }}
              className="p-3 pl-3 hover:text-orange-600"
            >
              <Plus size={20} strokeWidth={3} />
            </button>
          </div>
          <button
            className="flex items-center justify-center gap-2 px-8 py-3 font-semibold text-black bg-orange-500 rounded-md hover:bg-orange-400"
            onClick={markAddToCartClicked}
          >
            <ShoppingCart className="mr-2" />
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
}

const BorderShape = forwardRef<HTMLDivElement, { isVisible: boolean }>(
  (props, ref) => {
    const { isVisible } = props;

    return (
      <div
        ref={ref}
        className={`absolute inset-0 w-20 h-20 rounded-lg border-2 bg-zinc-50 border-orange-500 ${
          isVisible ? "block" : "hidden"
        }`}
      ></div>
    );
  }
);

export default HeroSection;
