import React, { useEffect } from "react";

import Header from "./Header";
import HeroSection from "./HeroSection";
import { ImagesProvider, useImages } from "./context/ImagesContext";
import toast, { Toaster } from "react-hot-toast";

import FullImages from "./FullImages";
import { ShoppingBagIcon } from "lucide-react";
import { Analytics } from "@vercel/analytics/react";
function App() {
  return (
    <div className="font-normal font-kumbhSans md:mx-4 lg:mx-20 xl:mx-40 ">
      <ImagesProvider>
        <Toast />
        <>
          <FullImages />
          <Header />
          <HeroSection />
        </>
      </ImagesProvider>
      <Analytics />
    </div>
  );
}
function Toast() {
  const { showMaxQuantityToast, recordedTimeForToast } = useImages();
  useEffect(() => {
    if (showMaxQuantityToast) {
      toast.remove();
      toast.custom(<CustomToast />, {
        duration: 2000,
        position: window.innerWidth <= 768 ? "top-center" : "bottom-center",
      });
    }
  }, [showMaxQuantityToast, recordedTimeForToast]);

  return <Toaster />;
}

function CustomToast() {
  return (
    <div className="flex items-center gap-2 px-6 py-4 mt-2 text-sm font-medium bg-orange-400 rounded-md shadow-lg select-none md:gap-4 md:my-6 md:bg-orange-400/80 shadow-zinc-500 text-stone-800 animate-barDown md:animate-barUp">
      <ShoppingBagIcon strokeWidth={1.37} className="w-6" />
      Only 8 unit(s) allowed per order
    </div>
  );
}

export default App;
