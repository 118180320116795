import React, { useState, useRef, useEffect } from "react";
import { Menu, ShoppingCart, Trash2Icon } from "lucide-react";
import Logo from "./images/logo.svg";
import UserAvatar from "./images/image-avatar.png";
import RightSlidingMenu from "./RightSlidingMenu";
import { useImages } from "./context/ImagesContext";
import Thumbnail from "./images/image-product-1-thumbnail.jpg";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useMediaQuery } from "react-responsive";
import * as Popover from "@radix-ui/react-popover";

function Header() {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [hoveredItemPosition, setHoveredItemPosition] = useState({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const hrRef = useRef<HTMLHRElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const navItems = ["Collections", "Men", "Women", "About", "Contact"];

  const handleMouseEnter = (item: string, e) => {
    setHoveredItem(item);
    if (hrRef.current === null) return;
    const rect = e.target.getBoundingClientRect();
    const hrRect = hrRef.current.getBoundingClientRect();
    setHoveredItemPosition({
      width: rect.width,
      left: rect.left,
      top: hrRect.top,
    });
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    const handleResize = () => {
      const isScreenSizeMdOrLarger = window.innerWidth >= 768;
      if (isScreenSizeMdOrLarger) setIsMobileMenuOpen(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (headerRef.current === null) setHeaderHeight(0);
    else setHeaderHeight(headerRef.current.getBoundingClientRect().bottom);
  }, [headerRef.current]);

  return (
    <>
      <div ref={headerRef} className="relative select-none">
        <header className="flex justify-between items-center p-4 max-w-[1200px] mx-auto">
          <div className="flex gap-[6vw] justify-between items-center">
            <button
              className="md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <Menu />
            </button>
            <img src={Logo} alt="Logo" className="h-[6vw] max-h-6" />

            <div className="hidden md:flex gap-[2vw] space-x-4 text-gray-600 text-[15px]">
              {navItems.map((item) => (
                <div
                  key={item}
                  className="relative"
                  onMouseEnter={(e) => handleMouseEnter(item, e)}
                  onMouseLeave={handleMouseLeave}
                >
                  <p
                    className={`cursor-pointer ${
                      hoveredItem === item ? "text-black" : "text-gray-600"
                    }`}
                  >
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <CartUI headerHeight={headerHeight} />
            <img
              src={UserAvatar}
              alt="User avatar"
              className="w-8 h-8 rounded-full"
            />
          </div>
        </header>
        <hr ref={hrRef} className=" hidden md:block max-w-[1200px] mx-auto" />

        <RightSlidingMenu
          open={isMobileMenuOpen}
          onClose={() => setIsMobileMenuOpen(false)}
        />
      </div>
      {hoveredItem && (
        <div
          className="absolute h-1 transition-all duration-300 bg-orange-500"
          style={{
            width: hoveredItemPosition.width,
            left: hoveredItemPosition.left,
            top: hoveredItemPosition.top - 3,
          }}
        />
      )}
    </>
  );
}

function CartUI(props: { headerHeight: number }) {
  const { headerHeight } = props;

  const isTouchDevice = useMediaQuery({ query: "(pointer: coarse)" });

  if (isTouchDevice) {
    return (
      <Popover.Root>
        <Popover.Trigger asChild>
          <div className="relative cursor-pointer">
            <ShoppingCart />
            <CartQuantity />
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="w-[96vw] mx-[2vw] bg-white rounded-lg outline-none">
            <CartPopoverUiTouchDevice headerHeight={headerHeight} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="relative cursor-pointer">
            <ShoppingCart />
            <CartQuantity />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="mt-3 mr-2 bg-white border-t rounded-lg shadow-xl outline-none shadow-zinc-500 border-t-slate-200">
            <CartTooltipUiNonTouchDevice />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function CartPopoverUiTouchDevice(props: Readonly<{ headerHeight: number }>) {
  const { headerHeight } = props;

  const { cartQuantity, clearCart } = useImages();
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > headerHeight) {
        setIsTooltipVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!isTooltipVisible) return null;

  if (cartQuantity === 0)
    return (
      <div className="flex flex-col justify-center w-full pt-4 pb-8 mt-8 mb-6 rounded-lg">
        <p className="pt-3 mt-2 mb-6 ml-4 font-semibold tracking-wide">Cart</p>
        <hr />
        <p className="px-20 py-16">Your cart is empty.</p>
      </div>
    );

  return (
    <div className="flex flex-col justify-center w-full pt-4 pb-8 mt-8 mb-6 rounded-lg">
      <p className="mt-1 mb-6 ml-4 font-semibold tracking-wide">Cart</p>
      <hr />
      <div className="flex gap-4 p-4 mt-1">
        <img
          src={Thumbnail}
          alt="Product thumbnail"
          className="w-12 h-12 rounded-[3px]"
        />
        <div>
          <p className="text-zinc-500 ">Fall Limited Edition Sneakers</p>
          <p className="text-zinc-500 ">
            $125 x {cartQuantity}
            <span className="ml-1.5 text-black">${125 * cartQuantity}</span>
          </p>
        </div>
        <Trash2Icon
          strokeWidth={1.3}
          color="rgb(133 133 142)"
          className="cursor-pointer hover:text-[rgb(237,131,46)]"
          onClick={clearCart}
        />
      </div>

      <button className="bg-orange-500 text-black font-semibold w-[90vw] py-[14px] mt-3 mx-2 rounded-lg outline-none hover:bg-orange-400">
        Checkout
      </button>
    </div>
  );
}

function CartTooltipUiNonTouchDevice() {
  const { cartQuantity, clearCart } = useImages();

  if (cartQuantity === 0)
    return (
      <div className="rounded-lg">
        <p className="pt-3 mt-2 mb-6 ml-4 font-semibold tracking-wide">Cart</p>
        <hr />
        <p className="px-20 py-20">Your cart is empty.</p>
      </div>
    );

  return (
    <div className="flex flex-col justify-center pb-8 mb-6 rounded-lg">
      <p className="mt-2 mb-8 ml-4 font-semibold tracking-wide">Cart</p>
      <hr />
      <div className="flex gap-4 p-4 mt-1">
        <img
          src={Thumbnail}
          alt="Product thumbnail"
          className="w-12 h-12 rounded-[3px]"
        />
        <div>
          <p className="text-zinc-500 ">Fall Limited Edition Sneakers</p>
          <p className="text-zinc-500 ">
            $125 x {cartQuantity}
            <span className="ml-1.5 text-black">${125 * cartQuantity}</span>
          </p>
        </div>
        <Trash2Icon
          strokeWidth={1.3}
          color="rgb(133 133 142)"
          className="cursor-pointer hover:text-[rgb(237,131,46)] hover:scale-100 scale-75 duration-300"
          onClick={clearCart}
        />
      </div>

      <button className="bg-orange-500 text-black font-semibold w-[94%] mx-3 py-[14px] mt-6 rounded-lg hover:bg-orange-400">
        Checkout
      </button>
    </div>
  );
}

function CartQuantity() {
  const { cartQuantity } = useImages();
  if (cartQuantity === 0) return null;
  return (
    <div className="absolute -top-[2px] -right-1 text-white rounded-full w-[18px] h-[13px] py-1 font-semibold bg-orange-500 text-[10px] flex justify-center items-center">
      {cartQuantity}
    </div>
  );
}

export default Header;
